import styled from '@emotion/styled';
import { mediaQueries } from '@smartproxy-web/ui';
import { DOM_STATE } from 'components/constants';
import { BaseSection } from 'components/Section';
import { richTextElements } from 'constants/common';
import { MEDIA_QUERIES } from 'globalStyles';
import { IColor } from 'types';
import hideable from 'utils/hideable';
import { theme } from 'theme-v2/theme';

export const StyledExpandableCardsSlice = styled(BaseSection)<IColor>(
  ({ background }) => ({
    background,
    padding: '32px 20px',
    overflow: 'unset',
    '& + &': {
      paddingTop: '0',
      paddingBottom: '32px',
    },
    ul: {
      listStyle: 'none',
    },
    'ul, li': {
      padding: 0,
    },
    [mediaQueries.sm]: {
      padding: `${theme.spacings.L} 40px`,
      '& + &': {
        paddingTop: '0',
        paddingBottom: theme.spacings.L,
      },
    },
    [mediaQueries.lg]: {
      padding: `${theme.spacings.sectionSpacing} 40px`,
      '& + &': {
        paddingTop: '0',
        paddingBottom: theme.spacings.sectionSpacing,
      },
    },
  })
);

export const StyledExpandablePricingCardsContainer = styled.div({
  overflow: 'initial',
  fontSize: theme.fonts.size.p_small,
  lineHeight: theme.fonts.lineHeight.p_small,
  color: theme.colors.black.default,
  boxSizing: 'border-box',
  width: '100%',
  padding: '36px 48px',
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  borderRadius: '12px',
  boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
  gap: theme.spacings.L,
  background: theme.colors.white.default,

  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: '1fr',
    gap: theme.spacings.S,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    padding: '32px 20px',
  },
});

export const StyledProductGroupWrapper = styled.div({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingLeft: '20px',
  alignSelf: 'flex-start',

  [MEDIA_QUERIES.TABLET]: {
    paddingLeft: 0,
  },

  p: {
    color: theme.colors.gray.default,
    fontSize: theme.fonts.size.p_small,
  },
  a: {
    color: theme.colors.blue.default,
  },

  'h2, h3, h4, h5': {
    color: theme.colors.black.S900,
    fontSize: theme.fonts.size.h4,
    lineHeight: theme.fonts.lineHeight.h4,
    marginBottom: '16px',
    fontWeight: '500',
  },
});

export const StyledProductsWrapper = styled.ul({
  borderBottom: `1px solid ${theme.colors.neutral.N04}`,
  height: 'max-content',
  '> li+li': {
    borderTop: `1px solid ${theme.colors.neutral.N04}`,
  },
});

export const StyledProductItem = styled.li({
  display: 'flex',
  flexDirection: 'column',

  'input[type=checkbox]': {
    display: 'none',
  },
  'input[type=checkbox]:checked ~ .accordion-body': {
    gridTemplateRows: '1fr',
  },
  'input[type=checkbox]:checked ~ .accordion-trigger .dropdown-icon': {
    transform: 'rotate(180deg)',
  },
  ':first-of-type': {
    label: {
      paddingTop: 0,
    },
  },
  button: {
    margin: 0,
    marginBottom: theme.spacings.S,
    minWidth: '200px',
    width: 'unset',
  },
});

export const StyledProductAccordionTrigger = styled.label({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px 0',
  cursor: 'pointer',
  gap: theme.spacings.XS,

  [MEDIA_QUERIES.TABLET]: {
    gap: theme.spacings.XS,
  },
});

export const StyledImageTitleContainer = styled.div({
  flexGrow: 1,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '3fr 2fr',
  gap: theme.spacings.XS,
  p: {
    fontSize: theme.fonts.size.p_small,
    lineHeight: theme.fonts.lineHeight.p_small,
    fontWeight: 500,
  },
  [MEDIA_QUERIES.TABLET]: {
    [richTextElements]: {
      fontSize: theme.fonts.size.p_small,
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gap: 0,
    gridTemplateColumns: '1fr',
  },
});

export const StyledImageWrapper = styled.div({
  width: 21,
  flexShrink: 0,
});

export const StyledTitleDetailsContainer = styled.div({
  [richTextElements]: {
    fontSize: theme.fonts.size.p_small,
    lineHeight: theme.fonts.lineHeight.p_small,
    fontFamily: theme.fonts.family.main,
    color: theme.colors.black.S900,
  },
  display: 'flex',
  alignItems: 'center',
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
  },
});

export const StyledTitleContainer = styled.div({
  [richTextElements]: {
    fontWeight: '500',
  },
});

export const StyledPriceWrapper = styled.p({
  fontSize: theme.fonts.size.p_small,
  lineHeight: theme.fonts.lineHeight.p_small,
  fontWeight: '300 !important',
  color: theme.colors.gray.S900,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    fontSize: theme.fonts.size.p_small,
    fontWeight: '400',
  },

  '.product-price': {
    fontWeight: 500,
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      fontSize: theme.fonts.size.p_small,
      fontWeight: '400',
    },
  },
});

export const StyledProductDropdownIconWrapper = styled.div({
  transition: 'transform 0.35s ease-in-out',
});

export const StyledProductAccordionBody = styled.div({
  display: 'grid',
  gridTemplateRows: '0fr',
  transition: 'grid-template-rows 0.3s ease-in-out',

  '> div': {
    overflow: 'hidden',
  },
});
export const StyledCheckmarkListWrapper = styled.ul({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacings.S,
  marginTop: '2px',

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacings.XS,
  },
});

export const StyledCheckmarkListItemWrapper = styled.li({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '4px',

  p: {
    fontWeight: 300,
  },
});
export const StyledProductDescription = styled.div({
  marginTop: '16px',
  marginBottom: theme.spacings.S,

  'p, em, strong, a': {
    display: 'inline',
    fontSize: theme.fonts.size.p_small,
    color: theme.colors.gray.default,
  },

  a: {
    paddingLeft: '4px',
    color: theme.colors.blue.default,
    [DOM_STATE.HOVER]: {
      textDecoration: 'underline',
    },
  },
});

export const StyledBadge = styled.div<{ color: string }>(({ color }) => ({
  display: 'flex',
  height: '16px',
  alignItems: 'center',
  borderRadius: '20px',
  padding: '0px 8px 0px 8px',
  fontWeight: 600,
  fontSize: '8px',
  lineHeight: '16px',
  letterSpacing: '0.1em',
  backgroundColor: theme.colors[color].default,
  border: `1px solid ${theme.colors[color].default}`,
  color:
    color === 'yellow' || color === 'green'
      ? theme.colors.black.default
      : theme.colors.white.default,
  textTransform: 'uppercase',
  alignSelf: 'center',
  width: 'max-content',
  marginLeft: theme.spacings.XS,
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    alignSelf: 'flex-start',
    marginLeft: 0,
    marginBottom: '6px',
  },
}));

export const HideableBadge = hideable(StyledBadge);
